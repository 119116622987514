// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-gerson-lazaro-js": () => import("./../../../src/pages/gerson-lazaro.js" /* webpackChunkName: "component---src-pages-gerson-lazaro-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-parts-blog-post-blog-post-js": () => import("./../../../src/parts/blog-post/blog-post.js" /* webpackChunkName: "component---src-parts-blog-post-blog-post-js" */)
}

